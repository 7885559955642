import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const TopBar = () => {
  const [showDropdown, setShowDropdown] = useState(false); // Estado para controlar el dropdown

  const toggleDropdown = () => setShowDropdown(!showDropdown); // Alternar visibilidad del dropdown

  return (
    <nav style={styles.navBar}>
      {/* Logo */}
      <div style={styles.logoContainer}>
        <Link to="/">
          <img src="../maxidinos.png" alt="Logo" style={styles.logo} />
        </Link>
      </div>

      {/* Menú de navegación */}
      <ul style={styles.navList}>
        <li>
          <Link to="/" style={styles.navItem}>Inicio</Link>
        </li>
        <li>
          <Link to="/sobre-nosotros" style={styles.navItem}>Sobre Nosotros</Link>
        </li>
        <li style={styles.dropdownContainer}>
          {/* Opción "Periodos" que despliega el menú al hacer click */}
          <span style={styles.navItem} onClick={toggleDropdown}>Periodos</span>
          {showDropdown && (
            <ul style={styles.dropdownMenu}>
              <li><Link to="/periodo-triasico" style={styles.dropdownItem}>Triásico</Link></li>
              <li><Link to="/periodo-jurasico" style={styles.dropdownItem}>Jurásico</Link></li>
              <li><Link to="/periodo-cretacico" style={styles.dropdownItem}>Cretácico</Link></li>
            </ul>
          )}
        </li>
      </ul>
    </nav>
  );
};

const styles = {
  navBar: {
    backgroundColor: '#D8C8B0',
    padding: '10px 20px',
    position: 'auto',
    width: '100%',
    top: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    marginLeft: -50,
    justifyContent: 'space-around',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: '50px',
    width: 'auto',
  },
  navList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  navItem: {
    color: 'black',
    textDecoration: 'none',
    fontSize: '1rem',
    fontFamily: 'Arial, sans-serif',
    cursor: 'pointer',
  },
  dropdownContainer: {
    position: 'relative',
  },
  dropdownMenu: {
    position: 'absolute',
    top: '100%',
    left: 0,
    backgroundColor: '#D8C8B0',
    listStyleType: 'none',
    padding: '10px',
    margin: 0,
    border: '1px solid #ccc',
    borderRadius: '5px',
    width: '150px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  dropdownItem: {
    padding: '10px',
    textDecoration: 'none',
    color: 'black',
    display: 'block',
  },
};

export default TopBar;
