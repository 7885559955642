import React from 'react';
import { Link } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Necesario para Leaflet

function Cretacico() {
  const dinosaurs = [
    { name: 'Elasmosaurus', image: '../elasmosaurus.avif' },
    { name: 'Plesiosaurus', image: '../plesiosaurus.jpg' },
    { name: 'Mosasaurus', image: '../mosasaurus.webp' },
    { name: 'Kronosaurus', image: '../Kronosaurus.jpg' },
    { name: 'Tylosaurus', image: '../Tylosaurus.jpg' },
  ];

  const fossilLocations = [
    { name: 'Fósil de T-Rex', lat: 44.68, lng: -103.46 },
    { name: 'Fósil de Triceratops', lat: 46.87, lng: -113.99 },
    { name: 'Fósil de Velociraptor', lat: 43.93, lng: 12.45 },
  ];

  return (
    <div style={styles.pageContainer}>
      <h1 style={styles.title}>Período Cretácico</h1>
      <p style={styles.description}>El Cretácico fue la última era de los dinosaurios, con muchas especies icónicas acuáticas.</p>

      {/* Galería de dinosaurios */}
      <div style={styles.gallery}>
        {dinosaurs.map((dino, index) => (
          <div key={index} style={styles.dinoCard}>
            <Link to={`/dinosaurio/${dino.name.toLowerCase()}`}>
              <img src={dino.image} alt={dino.name} style={styles.dinoImage} />
            </Link>
            <h3>
              <Link to={`/dinosaurio/${dino.name.toLowerCase()}`} style={styles.dinoLink}>
                {dino.name}
              </Link>
            </h3>
          </div>
        ))}
      </div>

      <h2 style={styles.mapTitle}>Mapa de Ubicaciones de Fósiles</h2>
      <MapContainer center={[44.68, -103.46]} zoom={4} style={styles.map}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {fossilLocations.map((location, index) => (
          <Marker key={index} position={[location.lat, location.lng]}>
            <Popup>{location.name}</Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
}

const styles = {
  pageContainer: { marginTop: '50px', padding: '20px', textAlign: 'center' },
  title: { fontSize: '2.5rem', marginBottom: '20px' },
  description: { fontSize: '1.2rem', color: '#555', marginBottom: '40px' },
  gallery: { display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '40px' },
  dinoCard: { margin: '15px', padding: '15px', border: '1px solid #ddd', borderRadius: '10px', width: '200px', textAlign: 'center' },
  dinoImage: { width: '100%', height: '150px', objectFit: 'cover', borderRadius: '10px' },
  dinoLink: { textDecoration: 'none', color: '#007bff', fontWeight: 'bold' },
  mapTitle: { fontSize: '1.8rem', marginBottom: '20px' },
  map: { height: '400px', width: '100%' },
};

export default Cretacico;
