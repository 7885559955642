import React from 'react';
import { Link } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Necesario para Leaflet

function Triasico() {
  const reptilesMarinos = [
    { name: 'Nothosaurus', image: '../nothosaurus.jpg' },
    { name: 'Placodus', image: '../placodus.jpg' },
    { name: 'Cymbospondylus', image: '../cymbospondylus.jpg' },
    { name: 'Tanystropheus', image: '../tanystropheus.jpg' },
  ];

  const fossilLocations = [
    { name: 'Fósil de Nothosaurus', lat: 50.1, lng: 8.6 },
    { name: 'Fósil de Placodus', lat: 47.6, lng: 10.3 },
    { name: 'Fósil de Cymbospondylus', lat: 42.7, lng: 12.5 },
    { name: 'Fósil de Tanystropheus', lat: 46.5, lng: 9.8 },
  ];

  return (
    <div style={styles.pageContainer}>
      <h1 style={styles.title}>Período Triásico</h1>
      <p style={styles.description}>
        El Triásico fue un período clave para la evolución de los reptiles marinos, dando origen a algunos de los primeros depredadores oceánicos.
      </p>

      {/* Galería de reptiles marinos */}
      <div style={styles.gallery}>
        {reptilesMarinos.map((reptil, index) => (
          <div key={index} style={styles.card}>
            <Link to={`/dinosaurio/${reptil.name.toLowerCase()}`}>
              <img src={reptil.image} alt={reptil.name} style={styles.image} />
            </Link>
            <h3>
              <Link to={`/dinosaurio/${reptil.name.toLowerCase()}`} style={styles.link}>
                {reptil.name}
              </Link>
            </h3>
          </div>
        ))}
      </div>

      <h2 style={styles.mapTitle}>Mapa de Ubicaciones de Fósiles</h2>
      <MapContainer center={[47.0, 9.0]} zoom={5} style={styles.map}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {fossilLocations.map((location, index) => (
          <Marker key={index} position={[location.lat, location.lng]}>
            <Popup>{location.name}</Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
}

const styles = {
  pageContainer: { marginTop: '50px', padding: '20px', textAlign: 'center' },
  title: { fontSize: '2.5rem', marginBottom: '20px' },
  description: { fontSize: '1.2rem', color: '#555', marginBottom: '40px' },
  gallery: { display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '40px' },
  card: { margin: '15px', padding: '15px', border: '1px solid #ddd', borderRadius: '10px', width: '200px', textAlign: 'center' },
  image: { width: '100%', height: '150px', objectFit: 'cover', borderRadius: '10px' },
  link: { textDecoration: 'none', color: '#007bff', fontWeight: 'bold' },
  mapTitle: { fontSize: '1.8rem', marginBottom: '20px' },
  map: { height: '400px', width: '100%' },
};

export default Triasico;
