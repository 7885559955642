import React from 'react';
import { useParams } from 'react-router-dom';

const DinosaurDetail = () => {
  const { name } = useParams(); // Obtener el nombre del dinosaurio desde la URL

  const dinosaurs = {
    elasmosaurus: {
      name: 'Elasmosaurus',
      description: 'El Elasmosaurus fue un género de plesiosaurio marino con un cuello extremadamente largo. Vivió durante el Cretácico Superior, hace 80 millones de años.',
      image: '../elasmosaurus.avif',
      length: '14 metros',
      diet: 'Carnívoro (alimentándose principalmente de peces pequeños).',
      funFact: 'Usaba su largo cuello para atrapar presas sin ser detectado.'
    },
    plesiosaurus: {
      name: 'Plesiosaurus',
      description: 'El Plesiosaurus fue un reptil marino con un cuerpo aerodinámico y aletas poderosas que le permitían nadar rápidamente en los océanos.',
      image: '../plesiosaurus.jpg',
      length: '12 metros',
      diet: 'Carnívoro (se alimentaba de peces y calamares).',
      funFact: 'El Plesiosaurus era excelente para maniobrar bajo el agua con sus aletas en forma de remo.'
    },
    mosasaurus: {
      name: 'Mosasaurus',
      description: 'El Mosasaurus fue un depredador marino gigante que gobernaba los océanos en el Cretácico. Tenía dientes afilados y una mandíbula poderosa.',
      image: '../mosasaurus.webp',
      length: '18 metros',
      diet: 'Carnívoro (se alimentaba de peces, calamares y reptiles).',
      funFact: 'Podía respirar aire y pasaba la mayor parte de su vida cerca de la superficie.'
    },
    kronosaurus: {
      name: 'Kronosaurus',
      description: 'El Kronosaurus era uno de los depredadores más grandes del Cretácico. Era conocido por su poderosa mordida y gran tamaño.',
      image: '../Kronosaurus.jpg',
      length: '10 metros',
      diet: 'Carnívoro (se alimentaba de otros reptiles marinos).',
      funFact: 'Tenía dientes enormes y era capaz de partir huesos con facilidad.'
    },
    tylosaurus: {
      name: 'Tylosaurus',
      description: 'El Tylosaurus fue un mosasáurido gigantesco, estrechamente relacionado con las serpientes modernas.',
      image: '../Tylosaurus.jpg',
      length: '15 metros',
      diet: 'Carnívoro (devoraba peces, aves y reptiles).',
      funFact: 'Era conocido por su habilidad para atacar tanto en el agua como en la superficie.'
    },
    ichthyosaurus: {
      name: 'Ichthyosaurus',
      description: 'El Ichthyosaurus fue un reptil marino con cuerpo hidrodinámico, similar al de un delfín moderno. Era uno de los nadadores más rápidos del Jurásico.',
      image: '../ichthyosaurus.jpg',
      length: '2 a 4 metros',
      diet: 'Carnívoro (se alimentaba de peces y calamares).',
      funFact: 'Era vivíparo, lo que significa que daba a luz crías vivas en lugar de poner huevos.',
    },
    liopleurodon: {
      name: 'Liopleurodon',
      description: 'El Liopleurodon era un pliosaurio con un cuerpo robusto y una cabeza grande con mandíbulas poderosas. Era uno de los depredadores más temibles de los océanos.',
      image: '../liopleurodon.jpg',
      length: '6 a 10 metros',
      diet: 'Carnívoro (depredador de peces y reptiles marinos).',
      funFact: 'Se cree que tenía un sentido del olfato agudo, capaz de detectar presas a larga distancia.',
    },
    metriorhynchus: {
      name: 'Metriorhynchus',
      description: 'El Metriorhynchus era un crocodiliforme adaptado al medio marino, con extremidades en forma de aletas y una cola similar a la de un pez.',
      image: '../metriorhynchus.jpg',
      length: '3 metros',
      diet: 'Carnívoro (comía peces y pequeños reptiles).',
      funFact: 'A diferencia de los cocodrilos actuales, estaba completamente adaptado a la vida en el mar y no necesitaba volver a tierra.',
    },
    ophthalmosaurus: {
      name: 'Ophthalmosaurus',
      description: 'El Ophthalmosaurus era un ictiosaurio famoso por sus enormes ojos, lo que le permitía cazar en aguas profundas con poca luz.',
      image: '../ophthalmosaurus.jpg',
      length: '5 a 6 metros',
      diet: 'Carnívoro (se alimentaba principalmente de peces y cefalópodos).',
      funFact: 'Sus ojos eran tan grandes que ocupaban casi un tercio de su cráneo, permitiéndole ver en la oscuridad total de las profundidades.',
    },
    nothosaurus: {
      name: 'Nothosaurus',
      description: 'El Nothosaurus era un reptil semiacuático con cuerpo alargado y extremidades en forma de remo. Vivió en mares poco profundos durante el Triásico.',
      image: '../nothosaurus.jpg',
      length: '4 metros',
      diet: 'Carnívoro (se alimentaba de peces).',
      funFact: 'Era un excelente nadador, pero también podía moverse en tierra.',
    },
    placodus: {
      name: 'Placodus',
      description: 'El Placodus era un reptil marino con un cuerpo robusto y dientes especializados para triturar conchas.',
      image: '../placodus.jpg',
      length: '2 metros',
      diet: 'Moluscos y crustáceos.',
      funFact: 'Usaba sus dientes frontales como pinzas para arrancar moluscos de las rocas.',
    },
    cymbospondylus: {
      name: 'Cymbospondylus',
      description: 'El Cymbospondylus fue un ictiosaurio temprano, con un cuerpo largo y flexible, adaptado para nadar en los mares del Triásico.',
      image: '../cymbospondylus.jpg',
      length: '10 metros',
      diet: 'Carnívoro (peces y cefalópodos).',
      funFact: 'Es uno de los primeros grandes ictiosaurios conocidos.',
    },
    tanystropheus: {
      name: 'Tanystropheus',
      description: 'El Tanystropheus era un reptil con un cuello extremadamente largo que constituía la mitad de su cuerpo.',
      image: '../tanystropheus.jpg',
      length: '6 metros',
      diet: 'Pequeños animales acuáticos.',
      funFact: 'Se debate si vivía principalmente en el agua o en tierra.',
    },
  };

  const dinosaur = dinosaurs[name.toLowerCase()]; // Buscar el dinosaurio por nombre

  if (!dinosaur) {
    return <h1>Dinosaurio no encontrado</h1>;
  }

  return (
    <div style={styles.container}>
      <h1>{dinosaur.name}</h1>
      <img src={dinosaur.image} alt={dinosaur.name} style={styles.image} />
      <p>{dinosaur.description}</p>
      <ul>
        <li><strong>Longitud:</strong> {dinosaur.length}</li>
        <li><strong>Dieta:</strong> {dinosaur.diet}</li>
      </ul>

      {/* Sección interactiva si el dinosaurio tiene un dato curioso */}
      {dinosaur.funFact && (
        <div style={styles.interactiveSection}>
          <h2>¿Sabías que...?</h2>
          <p>{dinosaur.funFact}</p>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    marginTop: '50px',
    padding: '20px',
    textAlign: 'center',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  interactiveSection: {
    backgroundColor: '#e0f7fa',
    padding: '20px',
    borderRadius: '10px',
    marginTop: '30px',
  },
};

export default DinosaurDetail;
