import React from 'react';

const SobreNosotros = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Sobre Nosotros</h1>
      <p style={styles.paragraph}>
        Bienvenidos a nuestra enciclopedia digital sobre <strong>dinosaurios acuáticos</strong>. Aquí
        podrás encontrar información fascinante sobre estas criaturas prehistóricas que
        dominaron los océanos hace millones de años.
      </p>
      <p style={styles.paragraph}>
        Nuestro equipo se ha dedicado a recopilar y organizar datos, imágenes y descripciones
        para ofrecerte la información más precisa y detallada sobre estos increíbles animales.
        Nos apasiona la paleontología y esperamos que disfrutes explorando esta plataforma
        tanto como nosotros disfrutamos creándola.
      </p>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#f0f8ff', // Color de fondo suave
    padding: '40px',            // Espaciado interno amplio
    borderRadius: '8px',        // Bordes redondeados
    maxWidth: '800px',          // Limitar el ancho máximo del contenido
    margin: '40px auto',        // Centrar el contenido y darle margen superior
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Sombra para dar efecto de tarjeta
  },
  title: {
    fontSize: '2.5rem',         // Tamaño de letra grande para el título
    color: '#2c3e50',           // Color de texto oscuro
    textAlign: 'center',        // Centrar el texto
    marginBottom: '20px',       // Espacio debajo del título
  },
  paragraph: {
    fontSize: '1.2rem',         // Tamaño de letra para el cuerpo del texto
    color: '#34495e',           // Color de texto más suave
    lineHeight: '1.6',          // Espaciado entre líneas para mejorar legibilidad
    textAlign: 'justify',       // Justificar el texto para alineación limpia
    marginBottom: '15px',       // Espaciado entre párrafos
  },
};

export default SobreNosotros;
